import React, { createContext, useContext, useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

const AnalyticsContext = createContext(null);

export const AnalyticsProvider = ({ children }) => {
    useEffect(() => {
        amplitude.init(process.env.GATSBY_AMPLITUDE_API_KEY)
    }, []);

    return (
        <AnalyticsContext.Provider value={amplitude}>
            {children}
        </AnalyticsContext.Provider>
    );
};

export const useAnalytics = () => {
    return useContext(AnalyticsContext);
};
