import React, { createContext, useState, useContext, useEffect } from 'react';

const defaultHoverContext = {
    hoverEffect: 'default',
    setHoverEffect: () => 'default',
};

const HoverContext = createContext(defaultHoverContext);

export const HoverProvider = ({ children }) => {
    const [hoverEffect, setHoverEffect] = useState('default');

    useEffect(() => {
        const handleMouseEnter = (event) => {
            if (event.target.tagName === 'A') {
                setHoverEffect('blue');
            }
        };

        const handleMouseLeave = (event) => {
            if (event.target.tagName === 'A') {
                setHoverEffect('default');
            }
        };

        document.addEventListener('mouseover', handleMouseEnter);
        document.addEventListener('mouseout', handleMouseLeave);

        return () => {
            document.removeEventListener('mouseover', handleMouseEnter);
            document.removeEventListener('mouseout', handleMouseLeave);
        };
    }, []);

    return (
        <HoverContext.Provider value={{ hoverEffect, setHoverEffect }}>
            {children}
        </HoverContext.Provider>
    );
};

export const useHover = () => {
    return useContext(HoverContext);
};
