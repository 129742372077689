import React from 'react';
import { TransitionProvider } from './src/hooks/useTransition';
import { AnalyticsProvider } from './src/hooks/useAnalytics';
import { HoverProvider } from './src/hooks/useHover';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
    return (
        <HoverProvider>
            <AnalyticsProvider>
                <TransitionProvider>{element}</TransitionProvider>
            </AnalyticsProvider>
        </HoverProvider>
    );
};

const transitionDelay = 750;

export const shouldUpdateScroll = () => false;
export const onRouteUpdate = () => window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
