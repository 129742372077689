import React, { createContext, useContext, useState } from 'react';

const defaultTransitionContext = {
    showTransition: true,
    disableTransition: () => null,
};

const TransitionContext = createContext(defaultTransitionContext);

export const TransitionProvider = ({ children }) => {
    const [showTransition, setShowTransition] = useState(true);

    const disableTransition = () => {
        setShowTransition(false);
    };

    return (
        <TransitionContext.Provider value={{ showTransition, disableTransition }}>
            {children}
        </TransitionContext.Provider>
    );
};

export const useTransition = () => {
    return useContext(TransitionContext);
};
